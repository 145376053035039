import React, { Fragment, useState, useEffect } from 'react';
import { sessionActions, reroute } from "../../Actions";
import { connect } from 'react-redux';
import { Input, Button } from '../GeneralComponents/Elements';
import ReCAPTCHA from 'react-google-recaptcha';
import env from "react-dotenv";
import logoShort from '../../Images/LogoShort.PNG';
import PropTypes from 'prop-types';

const Register = ({ Register }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const [captchaToken, setCaptchaToken] = useState(null);

    const handleCaptchaChange = (token) => {
        setCaptchaToken(token);
    };

    const registerAccount = () => {
        Register({firstName, lastName, email, password})
    }

    return (
        <Fragment>
            <section className="h-full mb-20 min-h-screen">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto">
                <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <a href="#" className="flex items-center text-center justify-center mb-8 text-2xl font-semibold text-gray-900">
                            <img className="w-8 h-8 mr-2" src={logoShort} alt="theory underground logo"/>
                            Theory Underground
                        </a>
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900">
                            Create an account
                        </h1>
                            <Input id={"first_name"} text={firstName} title={"First Name"} placeholder={"John"} required={true} onChange={setFirstName}/>
                            <Input id={"last_name"} text={lastName} title={"Last Name"} placeholder={"Doe"} required={true} onChange={setLastName}/>
                            <Input id={"email"} text={email} title={"Email"} placeholder={"john.doe@gmail.com"} required={true} onChange={setEmail}/>
                            <Input id={"password"} text={password} title={"Password"} placeholder={"********"} type={"password"} required={true} onChange={setPassword}/>
                            <Input id={"confirm_password"} text={confirmPassword} title={"Confirm Password"} placeholder={"********"} type={"password"} required={true} onChange={setConfirmPassword}/>

                            {confirmPassword != password && confirmPassword != "" ? 
                                <p className="text-sm font-normal text-red-500">
                                    Passwords do not match.
                                </p> 
                            : null}

                            <p className="text-sm font-light text-gray-500">
                                Passwords must be at least 8 characters long, contain at least one uppercase letter, one number, and one special character.
                            </p>


                            <button type="submit" className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center" onClick={() => registerAccount()}>Create an account</button>

                            <p className="text-sm font-light text-gray-500">
                                Already have an account? <button className="font-medium text-blue-600 hover:underline" onClick={() => reroute("login")} >Log In</button>
                            </p>
                    </div>
                </div>
            </div>
            </section>
        </Fragment>
    )
};

Register.propTypes = {
    Register: PropTypes.func.isRequired,
};

const connection = connect((state) => ({}), {
    Register: sessionActions.Register,
})(Register);
export { connection as Register };