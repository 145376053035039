import {apiCall} from "./actionFunctions";
import { alertActions } from "../Actions";

export const objectActions = {
    CreateObject,
    GetObject,
    GetObjectById,
    UpdateObject,
    DeleteObject,
}

function CreateObject(object, type) {
    return apiCall("POST", "object", {object, type}, (data, dispatch) => {
        const { message } = data;
        if(message) {
            dispatch(alertActions.Success(message)) 
        }
    });
}
function GetObject(info="") {
    return apiCall("GET", "object", {params: info}, (data, dispatch) => {
        const { message } = data;
        if(message) {
            dispatch(alertActions.Success(message)) 
        }
    });
}
function GetObjectById(info="") {
    return apiCall("GET", "object/id", {params: info}, (data, dispatch) => {
        const { message } = data;
        if(message) {
            dispatch(alertActions.Success(message)) 
        }
    });
}
function UpdateObject(object, type) {
    return apiCall("PUT", "object", {object, type}, (data, dispatch) => {
        const { message } = data;
        if(message) {
            dispatch(alertActions.Success(message)) 
        }

    });
}
function DeleteObject(object, type) {
    return apiCall("DELETE", "object", {object, type}, (data, dispatch) => {
        const { message } = data;
        if(message) {
            dispatch(alertActions.Success(message)) 
        }
    });
}
