import React, { Fragment, useEffect, useState } from 'react';
import { userActions } from "../../Actions";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import e1 from '../../Images/Events/event1.png'
import e2 from '../../Images/Events/event2.png'
import e3 from '../../Images/Events/event3.png'
import e4 from '../../Images/Events/event4.png'
import e5 from '../../Images/Events/event5.png'
import e6 from '../../Images/Events/event6.png'
import e7 from '../../Images/Events/event7.png'
import e8 from '../../Images/Events/event8.png'
import e9 from '../../Images/Events/event9.png'
import e10 from '../../Images/Events/event10.png'
import e11 from '../../Images/Events/event11.png'

import map from '../../Images/Events/EventMap.png'

const Events = () => {
    const courses = [
        {src: e1, alt: "Event1", link: ""},
        {src: e2, alt: "Event2", link: ""},
        {src: e3, alt: "Event3", link: ""},
        {src: e4, alt: "Event4", link: ""},
        {src: e5, alt: "Event5", link: ""},
        {src: e6, alt: "Event6", link: ""},
        {src: e7, alt: "Event7", link: ""},
        {src: e8, alt: "Event8", link: ""},
        {src: e9, alt: "Event9", link: ""},
        {src: e10, alt: "Event10", link: ""},
        {src: e11, alt: "Event11", link: ""},

    ]
    return (
        <Fragment>
            <section className="h-full py-12 pb-20 relative">
                <div className="absolute top-0 left-0 right-0 bottom-0 bg-black opacity-40 z-0"></div>               

                <div className="relative gap-8 items-center py-8 px-12 mx-auto h-fit rounded-lg w-4/5 z-10">
                    
                    <div className="relative z-20 p-5 max-w-5xl mx-auto">
                        
                        <h2 className="mb-12 text-5xl text-center tracking-tight font-extrabold text-gray-100 drop-shadow-xl">TU EVENTS</h2>
                        <p className="text-center font-bold md:text-lg text-gray-100 mb-14 drop-shadow-xl">
                            Coming to a city near you. We tour. We epic marathon stream. We conference. We EMST(O)U(R)CON.
                        </p> 
                        <div className='relative'>
                            <div className="absolute inset-0 border-4 border-white blur-sm"></div>
                            <img 
                                alt={"Event Map"} 
                                src={map} 
                                className="object-cover w-auto m-16 mx-auto shadow-md rounded-lg"
                            />
                        </div>

                        <h2 className="mb-12 text-3xl text-center tracking-tight font-extrabold text-gray-100 drop-shadow-xl">Event Gallery</h2>

                        <div className="md:grid md:grid-cols-2 gap-8">
                            {courses.map(({ src, alt, locked=false }, index) => (
                                <div key={index} className="relative w-auto h-72 overflow-hidden rounded-lg shadow-md mx-auto">
                                    <div className="absolute inset-0 border-4 border-white blur-sm"></div>

                                    <img 
                                        alt={alt} 
                                        src={src} 
                                        className="object-cover w-72 h-full shadow-md"
                                    />
                                    {locked && (
                                        <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-60 z-10">
                                            <svg className='' xmlns="http://www.w3.org/2000/svg" height="42px" viewBox="0 -960 960 960" width="42px" fill="#FFFFFF"><path d="M240-80q-33 0-56.5-23.5T160-160v-400q0-33 23.5-56.5T240-640h40v-80q0-83 58.5-141.5T480-920q83 0 141.5 58.5T680-720v80h40q33 0 56.5 23.5T800-560v400q0 33-23.5 56.5T720-80H240Zm0-80h480v-400H240v400Zm240-120q33 0 56.5-23.5T560-360q0-33-23.5-56.5T480-440q-33 0-56.5 23.5T400-360q0 33 23.5 56.5T480-280ZM360-640h240v-80q0-50-35-85t-85-35q-50 0-85 35t-35 85v80ZM240-160v-400 400Z"/></svg>
                                        </div>
                                    )}
                                    
                                </div>
                                
                            ))}
                        </div>
                    </div>
                </div>
            </section>

        </Fragment>
    )
};

Events.propTypes = {
};

const connection = connect((state) => ({}), {})(Events);
export { connection as Events };