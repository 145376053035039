import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { useAuth } from '../../Context/AuthContext'; 
import {PageNotFound} from './notfound';
import PropTypes from 'prop-types';

const ProtectedRoute = ({ allowed, children }) => {
    const auth = useAuth(); 
    if(!auth) return <PageNotFound access="denied"/>;
    const isAllowed = allowed.includes(auth.clearance) || allowed.length == 0;
    if (!isAllowed) return <PageNotFound access="denied"/>;
    return children; 
};

ProtectedRoute.propTypes = {
    allowed: PropTypes.array.isRequired,
    children: PropTypes.node.isRequired,
};

const connection = connect((state) => ({}), {})(ProtectedRoute);
export { connection as ProtectedRoute };