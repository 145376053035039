import React, { Fragment, useState, useEffect } from 'react';
import { sessionActions, reroute } from "../../Actions";
import { connect } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import env from "react-dotenv";
import GoogleButton from 'react-google-button'
import logoShort from '../../Images/LogoShort.PNG';
import PropTypes from 'prop-types';

const Login = ({ Login, LoginGoogle }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [captchaToken, setCaptchaToken] = useState(null);

    const login = () => {
        // const {useCaptcha} = session
        // session.useCaptcha ? Login({email, password, useCaptcha, captchaToken}) : Login({email, password})
        Login({email, password})
    }

    const handleCaptchaChange = (token) => {
        setCaptchaToken(token);
    };

    return (
        <Fragment>
            <section className="h-full mb-20 min-h-screen">
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto ">
                    <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <a href="#" className="flex items-center text-center justify-center mb-8 text-2xl font-semibold text-gray-900">
                                <img className="w-8 h-8 mr-2" src={logoShort} alt="theory underground logo"/>
                                Theory Underground
                            </a>
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                                Sign in to your account
                            </h1>
                            <div>
                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Your email</label>
                                <input type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5" placeholder="john.doe@gmail.com" required="" value={email} onChange={(e) => setEmail(e.target.value)}/>
                            </div>
                            <div>
                                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">Password</label>
                                <input type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5" required="" value={password} onChange={(e) => setPassword(e.target.value)}/>
                            </div>
                            <div className="flex items-center justify-between">
                                {/* <div className="flex items-start">
                                    <div className="flex items-center h-5">
                                        <input id="remember" aria-describedby="remember" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300" required=""/>
                                    </div>
                                    <div className="ml-3 text-sm">
                                        <label for="remember" className="text-gray-500">Remember me</label>
                                    </div>
                                </div> */}
                                <a href="#" className="text-sm ml-auto font-medium text-blue-600 hover:underline" onClick={() => reroute("resetpassword")}>Forgot password?</a>
                            </div>
                            <button className="w-full h-10 text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5py-2.5 text-center" onClick={() => login()}>Sign in</button>
                            
                            <p className="text-sm font-light text-gray-500">
                                Don’t have an account yet? <button className="font-medium text-blue-600 hover:underline" onClick={() => reroute("register")} >Sign up</button>
                            </p>

                            <div className="flex items-center">
                                <div className="border-t border-gray-300 flex-grow"></div>
                                <span className="text-xs text-gray-500 mx-2">OR</span>
                                <div className="border-t border-gray-300 flex-grow"></div>
                            </div>
                            
                            <div className="flex justify-center">
                                <GoogleButton
                                    type="light" 
                                    onClick={() => LoginGoogle()}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
};

Login.propTypes = {
    Login: PropTypes.func.isRequired,
    LoginGoogle: PropTypes.func.isRequired,
};

const connection = connect((state) => ({}), {
    Login: sessionActions.Login,
    LoginGoogle: sessionActions.LoginGoogle
})(Login);
export { connection as Login };