import React, { Fragment, useEffect, useState } from 'react';
import { reroute, userActions } from "../../Actions";
import { connect } from 'react-redux';
import background from '../../Images/herobackground.png';
import logoFull from '../../Images/LogoFull.jpg';
import PropTypes from 'prop-types';

import person1 from '../../Images/People/person1.png';
import person2 from '../../Images/People/person2.png';
import person3 from '../../Images/People/person3.png';
import person4 from '../../Images/People/person4.png';
import person5 from '../../Images/People/person5.png';

import map from '../../Images/Events/EventMap.png';


const Home = () => {
    return (
        <Fragment>
            <section className="h-full py-12 pb-20 relative">
                <div>
                    <div>
                        <h2 className="mb-12 text-5xl text-center tracking-tight font-extrabold text-white drop-shadow-lg">
                            Theory Underground
                        </h2>
                        <p className="text-center font-bold md:text-lg text-gray-100 mb-16 drop-shadow-xl w-4/5 mx-auto drop-shadow-2xl">
                            Looking to accelerate your way into a fresh understanding on our current situation? The researchers, courses, and student community at TU is build to help you find your bearings. Critical social theory, political philosophy, the history of ideas, and cutting edge research - you will not find anything remotely like this anywhere else.
                        </p> 
                        <div className="relative flex items-center justify-center h-[500px] w-[500px] mx-auto mb-20">
                            <button onClick={()=>{reroute("subscribe")}} className="absolute text-center text-xl font-bold text-white bg-black bg-opacity-70 px-4 py-2 rounded-lg z-10">
                                Get Involved
                            </button>

                            {/* Star-shaped image positions */}
                            <img
                                src={person1}
                                alt="Image 1"
                                className="object-cover absolute top-0 left-1/2 transform -translate-x-1/2 w-32 h-32 rounded-lg border-4 border-white"
                            />
                            <img
                                src={person2}
                                alt="Image 2"
                                className="object-cover absolute top-1/2 left-0 transform -translate-y-1/2 w-32 h-32 rounded-lg border-4 border-white"
                            />
                            <img
                                src={person3}
                                alt="Image 3"
                                className="object-cover absolute top-1/2 right-0 transform -translate-y-1/2 w-32 h-32 rounded-lg border-4 border-white"
                            />
                            <img
                                src={person4}
                                alt="Image 4"
                                className="object-cover absolute bottom-0 left-1/4 transform translate-y-1/3 -translate-x-1/3 w-32 h-32 rounded-lg border-4 border-white"
                            />
                            <img
                                src={person5}
                                alt="Image 5"
                                className="object-cover absolute bottom-0 right-1/4 transform translate-y-1/3 translate-x-1/3 w-32 h-32 rounded-lg border-4 border-white"
                            />
                        </div>
                    </div>
                </div>

            </section>
            <section className="relative w-full mx-auto h-full flex items-center justify-center pb-16">
                <div className="absolute inset-0 bg-black opacity-80 z-0"></div>
                <div className="w-11/12 mx-auto z-10">

                    <h2 className='m-16 text-5xl text-center tracking-tight font-extrabold text-white drop-shadow-lg'>Check out our events!</h2>
                    <div className='relative'>
                    <div className="absolute inset-0 border-4 border-red-500 blur-sm"></div>

                        <img 
                            alt={"Event Map"} 
                            src={map} 
                            className="object-cover w-auto m-16 mx-auto shadow-md rounded-lg"
                        />
                    </div>
                </div>

            </section>
        </Fragment>
    )
};

Home.propTypes = {
};

const connection = connect((state) => ({}), {})(Home);
export { connection as Home };