import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from './';

const DisplayObject = ({
    object = {},
    editable = [],
    editCallback = () => {},
    deleteCallback = () => {},
    createCallback = () => {},
    type="",
    behavior="modify",
}) => {
    const [selectedObject, setSelectedObject] = useState(null);

    const handleSelectChange = (e) => {
        const selectedIndex = e.target.value;
        setSelectedObject(object[selectedIndex] || null);
    };

    const handleInputChange = (key, value) => {
        setSelectedObject((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    useEffect(() => {
        if(!Array.isArray(object) && !selectedObject) {
            setSelectedObject(object)
        }
    }, []);

    return (
        <Fragment>
            {object && Array.isArray(object) ? (
                <select
                    name="select"
                    onChange={handleSelectChange}
                    className="text-black p-2 mb-4 rounded"
                >
                    <option value="">-- Select an Object --</option>
                    {object.map((obj, index) => (
                        <option key={index} value={index}>
                            {obj.name || type + " " + (index + 1)}
                        </option>
                    ))}
                </select>
            ) : <h2>Fields filled with example values. Replace or remove to create. Note: You cannot delete prices, only deactive them. Creating a customer will create a user in the database as if they registered.</h2> }
            {selectedObject && (
                <div className="flex flex-col p-6 m-auto mt-4 rounded-lg border shadow border-gray-600 bg-gray-800 text-white">
                    {Object.keys(selectedObject)
                    .filter((key) => editable.includes(key))  // Only include keys that are in editableProperties
                    .map((key) => (
                        <div key={key} className="flex mb-2">
                        <label className="w-2/5 font-bold text-gray-300 m-2 text-right">{key}:</label>
                        <textarea
                            type="text"
                            name={key}
                            className="w-3/5 text-black p-2 rounded"
                            value={typeof selectedObject[key] === "object" ? JSON.stringify(selectedObject[key]) : selectedObject[key]}
                            onChange={(e) => handleInputChange(key, e.target.value)}  // Ensure this handles the change correctly
                        />
                        </div>
                    ))}
                    {Object.keys(selectedObject)
                    .filter((key) => !editable.includes(key))  // Only include keys that are in editableProperties
                    .map((key) => (
                        <div key={key} className="flex mb-2">
                            <p className="w-2/5 font-bold text-gray-300 m-2 text-right">{key}:</p>
                            <p className="w-3/5 break-words text-white border-2 border-white p-2 rounded">
                                {typeof selectedObject[key] === "object" ? JSON.stringify(selectedObject[key]) : selectedObject[key]}
                            </p>
                        </div>
                    ))}
                </div>
            )}
            <div className='flex gap-2'>
                {behavior == "modify" ? 
                    <Fragment>
                        <Button id="edit_obj" text="Update" onClick={() => editCallback(selectedObject, type)} />
                        <Button id="del_obj" text="Delete" onClick={() => deleteCallback(selectedObject, type)} />
                    </Fragment>
                : 
                    <Button id="crt_obj" text="Create" onClick={() => createCallback(selectedObject, type)} />
                }
            </div>
        </Fragment>
    );
};

DisplayObject.propTypes = {

};

export { DisplayObject };