import React, { Fragment, useEffect, useState } from 'react';
import { userActions, subscriptionActions, stripeActions } from "../../Actions";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {  } from "../../Actions";

const subscriptionCards = ({CheckoutSession, cart}) => {
    let subCards = [
        {
            title: "Trial Access",
            subTitle: "Best for people who ...",
            price: "30",
            line1: "Individual configuration",
            line2: "No setup, or hidden fees",
            line3: "Access to Basic Courses",
            line4: "Limited content",
            checkoutData: "price_1QLAUPK9wLlAVWAQiRTLvMIU",
        },
        {
            title: "Premium Access",
            subTitle: "Best for people who ...",
            price: "500",
            line1: "Individual configuration",
            line2: "No setup, or hidden fees",
            line3: "Access to All Courses",
            line4: "Exclusive content",
            checkoutData: "price_1QLATlK9wLlAVWAQb6rIRdIb",
        },
        {
            title: "Basic Access",
            subTitle: "Best for people who ...",
            price: "50",
            line1: "Individual configuration",
            line2: "No setup, or hidden fees",
            line3: "Access to Basic Courses",
            line4: "Limited content",
            checkoutData: "price_1QLATIK9wLlAVWAQgpF3CaBS",
        },
    ]
    return (
        <Fragment>
            {subCards.map(({ title, subTitle, price, line1, line2, line3, line4, checkoutData }, index) => (
                <div key={index} className="flex flex-col p-6 text-center rounded-lg border shadow border-gray-600 xl:p-8 bg-gray-800 text-white">
                    <h3 className="mb-4 text-2xl font-semibold">{title}</h3>
                    <p className="font-light sm:text-lg text-gray-400">{subTitle}</p>
                    <div className="flex justify-center items-baseline my-8">
                        <span className="mr-2 text-5xl font-extrabold">${price}</span>
                        <span className="text-gray-400">/month</span>
                    </div>
                    <ul role="list" className="mb-8 space-y-4 text-left">
                        <li className="flex items-center space-x-3">
                            <svg className="flex-shrink-0 w-5 h-5 text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path></svg>
                            <span>{line1}</span>
                        </li>
                        <li className="flex items-center space-x-3">
                            <svg className="flex-shrink-0 w-5 h-5 text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path></svg>
                            <span>{line2}</span>
                        </li>
                        <li className="flex items-center space-x-3">
                            <svg className="flex-shrink-0 w-5 h-5 text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path></svg>
                            <span>{line3}</span>
                        </li>
                        <li className="flex items-center space-x-3">
                            <svg className="flex-shrink-0 w-5 h-5 text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path></svg>
                            <span>{line4}</span>
                        </li>
                    </ul>
                    <button className="bg-blue-500 focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900" onClick={() => CheckoutSession(checkoutData, cart)}>Get Started</button>
                </div>
            ))}
        </Fragment>
    )        
}
const addonCards = ({cart, cartCallback,}) => {
    let addons = [
        {
            title: "The Mikey Seminar",
            subTitle: "Unlock access",
            price: "100",
            checkoutData: "price_1QLAQhK9wLlAVWAQiN9wVBly",
        },
        {
            title: "The David McKerracher Seminar",
            subTitle: "Unlock access",
            price: "100",
            checkoutData: "price_1QLAR9K9wLlAVWAQKO0BlLva",
        },
        {
            title: "The Benjamin Studebaker Seminar",
            subTitle: "Unlock access",
            price: "100",
            checkoutData: "price_1QLARWK9wLlAVWAQnSLAg2zA",
        },
        {
            title: "The Nina Power Seminar",
            subTitle: "Unlock access",
            price: "100",
            checkoutData: "price_1QLARvK9wLlAVWAQOHm5qznY",
        },
    ]
    
    return (
        <Fragment>
            {addons.map(({ title, subTitle, price, checkoutData }, index) => (
                <div
                    key={index}
                    className={`h-full flex flex-col p-6 m-auto rounded-lg border shadow border-gray-600 bg-gray-800 text-white w-full relative ${
                        cart.includes(checkoutData) ? 'opacity-50' : ''
                    }`}
                    onClick={() => cartCallback(checkoutData)} // Trigger callback on card click
                >
                    {cart.includes(checkoutData) && (
                        <div className="absolute top-0 left-0 right-0 bottom-0 bg-gray-500 bg-opacity-50 flex justify-center items-center rounded-lg">
                            <span className="text-green-500 text-4xl">&#10003;</span> {/* Green checkmark */}
                        </div>
                    )}
                    <h5 className="mb-2 text-lg font-semibold tracking-tight text-white text-center mt-auto">
                        {title}
                    </h5>
                    <div className="flex justify-center items-baseline my-4 mt-auto">
                        <span className="mr-2 text-lg font-extrabold">${price}</span>
                        <span className="text-gray-400 ">/month</span>
                    </div>

                    <p className="font-normal text-gray-400 text-center mb-10">{subTitle}</p>

                    <button
                        type="submit"
                        className={`mt-auto bg-gray-500 mt-4 focus:ring-4 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white focus:ring-primary-900 ${
                            cart.includes(checkoutData) ? 'bg-green-500 cursor-not-allowed' : 'bg-gray-500'
                        }`}
                        disabled={cart.includes(checkoutData)} // Disable button if item is in cart
                    >
                        {cart.includes(checkoutData) ? 'Added to Checkout' : 'Add to Checkout'}
                    </button>
                </div>

            ))}
        </Fragment>
    )        
}
const Subscribe = ({CheckoutSession, PortalSession, GetSubscription}) => {
    const [subscriptions, setSubscriptions] = useState(null);
    const [cart, setCart] = useState([]);

    useEffect(() => {
        const getSubscriptions = async () => {
            if (!subscriptions) {
                const subscriptionInfo = await GetSubscription();
                setSubscriptions(subscriptionInfo);
            }
        };
        getSubscriptions();
    }, []);

    const cartCallback = (id) => {
        if (!cart.includes(id)) {
            setCart(prevCart => [...prevCart, id]); // Add id to cart
        } else {
            setCart(prevCart => prevCart.filter(item => item !== id)); // Remove id from cart
        }
    };

    return (
        <section className="">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-white">Subscribe</h2>
                    <p className="mb-5 font-light sm:text-xl text-gray-300">Get access to our incredible seminars.</p>
                </div>
                <div className="gap-12 grid grid-cols-3 space-y-0 w-4/5 m-auto">
                    {subscriptionCards({CheckoutSession, cart})}
                </div>
                <h2 className="mb-4 text-2xl tracking-tight font-bold text-gray-100 text-center mt-10">Or Check Out These Course Addons</h2>
                <div className="grid grid-cols-4 mt-10 gap-6 w-11/12 m-auto">
                    {addonCards({cart, cartCallback})}
                </div>
            </div>
        </section>
)}

const Logo = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="14px"
        height="16px"
        viewBox="0 0 14 16"
        version="1.1"
    >
    <defs />
    <g id="Flow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g
        id="0-Default"
        transform="translate(-121.000000, -40.000000)"
        fill="#E184DF"
    >
        <path
        d="M127,50 L126,50 C123.238576,50 121,47.7614237 121,45 C121,42.2385763 123.238576,40 126,40 L135,40 L135,56 L133,56 L133,42 L129,42 L129,56 L127,56 L127,50 Z M127,48 L127,42 L126,42 C124.343146,42 123,43.3431458 123,45 C123,46.6568542 124.343146,48 126,48 L127,48 Z"
        id="Pilcrow"
        />
    </g>
    </g>
</svg>
);


Subscribe.propTypes = {
};

const connection = connect((state) => ({}), {
    CheckoutSession: stripeActions.CheckoutSession,
    PortalSession: stripeActions.PortalSession,
    GetSubscription: subscriptionActions.GetSubscription,
})(Subscribe);
export { connection as Subscribe };