import React, {Fragment} from 'react';
import { alertActions } from "../Actions";
import { connect } from 'react-redux';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { Alert } from '../Components/GeneralComponents';
import {Login, Account, VerifyEmail, ResetPassword, Register, OAuth} from '../Components/Session';
import {Home, Publications, Courses, Events, Merch} from '../Components/UserPages';
import {NavigationBar, ProtectedRoute, PageNotFound, Footer} from '../Components/GeneralComponents';
import {Subscribe, SubSuccess, Seminar, EditObject} from '../Components/Stripe';
import PropTypes from 'prop-types';

const App = ({ alert }) => {  
  const routes = 
  [
    { 
      access: ['admin'], //Admin Routes
      components: [
        {path: '/EditObject/', component: <EditObject />},
      ]
    },
    { access: ['user', 'admin'], //User Routes
      components: [
        {path: '/subscribe/', component: <Subscribe />},
        {path: '/Seminar/', component: <Seminar />},
      ]
    },
    { access: ['unverified', 'admin'], //Unverified Routes
      components: [
        {path: '/VerifyEmail/:token?', component: <VerifyEmail />},
      ]
    },
    { access: ['unverified', 'user', 'subscriber', 'admin'], //All User Routes
      components: [
        {path: '/Account', component: <Account />},
        {path: '/SubSuccess/', component: <SubSuccess />},
      ]
    },
    { access: [], //Unprotected Routes
      components: [
        {path: '/ResetPassword', component: <ResetPassword />},
        {path: '/Login', component: <Login />},
        {path: '/Register', component: <Register />},
        {path: '/OAuth', component: <OAuth />},
        {path: '/Publications', component: <Publications />},
        {path: '/Courses', component: <Courses />},
        {path: '/Events', component: <Events />},
        {path: '/Merch', component: <Merch />},
        {path: '/', component: <Home />},
      ]
    }
  ]

  return (
    <BrowserRouter>
      <NavigationBar />
      {alert && alert.message ? <Alert type={alert.type} context={alert.message}></Alert> : <Fragment></Fragment>}

      {alert && alert.loading ? <div className="loading-container"><div className="loading-text">Loading...</div></div>: <Fragment></Fragment>}
        <Routes>
          {routes.map(({ access, components }, index) => 
              components.map(({ path, component }, componentIndex) => (
                  <Route
                      key={`${index}-${componentIndex}`}
                      path={path}
                      element={<ProtectedRoute allowed={access}>{component}</ProtectedRoute>}
                  />
              ))
          )}
          <Route path='*' element={<PageNotFound />} />
        </Routes>
        <Footer></Footer>
    </BrowserRouter>
  )
}
App.propTypes = {
  alert: PropTypes.object,
};

function mapState(state) {
  const { alert } = state
  return { alert };
}

const actionCreators = {
  clearAlerts: alertActions.clear
};

export default connect(mapState, actionCreators)(App);