import React, { Fragment, useEffect, useState } from 'react';
import { objectActions, reroute } from "../../Actions";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DisplayObject } from '../GeneralComponents/Elements';

const EditObject = ({GetObject, UpdateObject, DeleteObject, CreateObject}) => {
    const [obs, setObs] = useState(null);
    const [customers, setCustomers] = useState(null);
    const [subscriptions, setSubscriptions] = useState(null);
    const [prices, setPrices] = useState(null);
    const [products, setProducts] = useState(null);
    const [subItems, setSubItems] = useState(null);
    const [type, setType] = useState(null);
    const [typec, setCType] = useState(null);
    const [cmAction, setcmAction] = useState(null);
    
    
    const [exampleObjects, setExampleObjects] = useState({
        customer: {
            "object": "customer",
            "address": null,
            "balance": 0,
            "created": 1680893993,
            "currency": null,
            "default_source": null,
            "delinquent": false,
            "description": null,
            "discount": null,
            "email": "jennyrosen@example.com",
            "invoice_prefix": "0759376C",
            "invoice_settings": {
              "custom_fields": null,
              "default_payment_method": null,
              "footer": null,
              "rendering_options": null
            },
            "livemode": false,
            "metadata": {},
            "name": "Jenny Rosen",
            "next_invoice_sequence": 1,
            "phone": null,
            "preferred_locales": [],
            "shipping": null,
            "tax_exempt": "none",
            "test_clock": null
          },
        customerEdit: ["address", 'description', 'email', 'metadata', 'name', 'payment_method', 'phone', 'shipping', 'tax'],
        price: {
            "object": "price",
            "active": true,
            "billing_scheme": "per_unit",
            "currency": "usd",
            "custom_unit_amount": null,
            "livemode": false,
            "lookup_key": null,
            "metadata": {},
            "nickname": null,
            "product": "prod_NZKdYqrwEYx6iK",
            "recurring": {
              "interval": "month",
            },
            "tax_behavior": "unspecified",
            "tiers_mode": null,
            "transform_quantity": null,
            "type": "recurring",
            "unit_amount": 1000,
          },
        priceEdit: ['currency', 'active', 'metadata', 'nickname', 'product', 'recurring', 'tax_behavior', 'unit_amount', 'lookup_key', 'billing_scheme', 'currency_options', 'custom_unit_amount', 'product_data', 'tiers', 'tiers_mode', 'transfer_lookup_key', 'transform_quantity', 'unit_amount_decimal'],
        subscription: {
            "object": "subscription",
            "application": null,
            "application_fee_percent": null,
            "automatic_tax": {
              "enabled": false,
              "liability": null
            },
            "billing_cycle_anchor": 1679609767,
            "billing_thresholds": null,
            "cancel_at": null,
            "cancel_at_period_end": false,
            "canceled_at": null,
            "cancellation_details": {
              "comment": null,
              "feedback": null,
              "reason": null
            },
            "collection_method": "charge_automatically",
            "created": 1679609767,
            "currency": "usd",
            "current_period_end": 1682288167,
            "current_period_start": 1679609767,
            "customer": "cus_Na6dX7aXxi11N4",
            "days_until_due": null,
            "default_payment_method": null,
            "default_source": null,
            "default_tax_rates": [],
            "description": null,
            "discount": null,
            "discounts": null,
            "ended_at": null,
            "invoice_settings": {
              "issuer": {
                "type": "self"
              }
            },
            "items": [
                {
                  "price": "price_1QJfmvK9wLlAVWAQ3RWwltDc"
                },
              ],
            "latest_invoice": "in_1MowQWLkdIwHu7ixuzkSPfKd",
            "livemode": false,
            "metadata": {},
            "next_pending_invoice_item_invoice": null,
            "on_behalf_of": null,
            "pause_collection": null,
            "payment_settings": {
              "payment_method_options": null,
              "payment_method_types": null,
              "save_default_payment_method": "off"
            },
            "pending_invoice_item_interval": null,
            "pending_setup_intent": null,
            "pending_update": null,
            "schedule": null,
            "start_date": 1679609767,
            "status": "active",
            "test_clock": null,
            "transfer_data": null,
            "trial_end": null,
            "trial_settings": {
              "end_behavior": {
                "missing_payment_method": "create_invoice"
              }
            },
            "trial_start": null
          },
        subscriptionEdit: ['customer', 'cancel_at_period_end', 'currency', 'default_payment_method', 'description', 'items', 'metadata', 'payment_behavior'],
        product: {
            "object": "product",
            "active": true,
            "created": 1678833149,
            "default_price": null,
            "description": null,
            "images": [],
            "marketing_features": [],
            "livemode": false,
            "metadata": {},
            "name": "Gold Plan",
            "package_dimensions": null,
            "shippable": null,
            "statement_descriptor": null,
            "tax_code": null,
            "unit_label": null,
            "updated": 1678833149,
            "url": null
          },
          productEdit: ['name', 'active', 'description', 'id', 'metadata', 'tax_code', 'default_price_data', 'images', 'marketing_features', 'package_dimensions', 'shippable', 'statement_descriptor', 'unit_label', 'url'],
        subItem: {
            "object": "subscription_item",
            "billing_thresholds": null,
            "created": 1680126546,
            "metadata": {},
            "price": {
              "id": "price_1Mr6rdLkdIwHu7ixwPmiybbR",
              "object": "price",
              "active": true,
              "billing_scheme": "per_unit",
              "created": 1680126545,
              "currency": "usd",
              "custom_unit_amount": null,
              "discounts": null,
              "livemode": false,
              "lookup_key": null,
              "metadata": {},
              "nickname": null,
              "product": "prod_NcLYGKH0eY5b8s",
              "recurring": {
                "aggregate_usage": null,
                "interval": "month",
                "interval_count": 1,
                "trial_period_days": null,
                "usage_type": "licensed"
              },
              "tax_behavior": "unspecified",
              "tiers_mode": null,
              "transform_quantity": null,
              "type": "recurring",
              "unit_amount": 1000,
              "unit_amount_decimal": "1000"
            },
            "quantity": 2,
            "subscription": "sub_1Mr6rbLkdIwHu7ix4Xm9Ahtd",
            "tax_rates": []
          },
        subItemEdit: ['metadata', 'payment_behavior', 'price', 'proration_behavior', 'quantity', 'billing_thresholds', 'discounts', 'off_session', 'price_data', 'proration_date', 'tax_rates'],
    });

    const editCallback = async (object, type) => {
        await UpdateObject(object, type).then(data => {
            updateObjects(data, "update")
        })
    };

    const deleteCallback = async (object, type) => {
        await DeleteObject(object, type).then(data => {
            updateObjects(data, "delete")
        })
    };

    const createObjects = async (object, type) => {
        await CreateObject(object, type).then(data => {
            reroute("editobject")
        })
    };

    const updateObjects = (data, func) => {
        const {obj, type, action} = data
        switch (type) {
            case 'customer':
                setCustomers((prevCustomers) => 
                    func === "delete" 
                        ? prevCustomers.filter(customer => customer.id !== obj.id)  // Remove the customer
                        : prevCustomers.map(customer => 
                            customer.id === obj.id ? { ...customer, ...obj } : customer  // Update the customer
                        )
                );
                break;
            case 'price':
                setPrices((prevPrices) => 
                    func === "delete"
                        ? prevPrices.filter(price => price.id !== obj.id)  // Remove the price
                        : prevPrices.map(price => 
                            price.id === obj.id ? { ...price, ...obj } : price  // Update the price
                        )
                );
                break;
            case 'subscription':
                setSubscriptions((prevSubscriptions) => 
                    func === "delete"
                        ? prevSubscriptions.filter(subscription => subscription.id !== obj.id)  // Remove the subscription
                        : prevSubscriptions.map(subscription => 
                            subscription.id === obj.id ? { ...subscription, ...obj } : subscription  // Update the subscription
                        )
                );
                break;
            case 'product':
                setProducts((prevProducts) => 
                    func === "delete"
                        ? prevProducts.filter(product => product.id !== obj.id)  // Remove the product
                        : prevProducts.map(product => 
                            product.id === obj.id ? { ...product, ...obj } : product  // Update the product
                        )
                );
                break;
            case 'subItem':
                setProducts((prevProducts) => 
                    func === "delete"
                        ? prevProducts.filter(subItem => subItem.id !== obj.id)  // Remove the subItem
                        : prevProducts.map(subItem => 
                            subItem.id === obj.id ? { ...subItem, ...obj } : subItem  // Update the subItem
                        )
                );
                break;
            default:
        }
    };


    useEffect(() => {
        const getObs = async () => {
            if (!obs) {
                const objs = await GetObject();
                if(objs.productData) {
                    setCustomers(objs.productData.customers.data)
                    setSubscriptions(objs.productData.subs.data)
                    setPrices(objs.productData.prices.data)
                    setProducts(objs.productData.products.data)
                    setSubItems(objs.productData?.subItem?.data)
                }
                setObs(objs);
            }
        };
        getObs();
    }, []);


    const updateType = (e) => {
        const selected = e.target.value;
        setType(selected)
    };
    const updateCType = (e) => {
        const selected = e.target.value;
        setCType(selected)
    };

    const handlecmAction = (e) => {
        const selected = e.target.value;
        setcmAction(selected)
    };

    return (
        <Fragment>
            <section className=" h-full pb-20">
                <div className="flex items-center justify-center px-6 py-8 mx-auto ">
                    <div className=" bg-white rounded-lg shadow md:mt-0 w-4/5 xl:p-0">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <h2 className="mb-12 text-3xl text-center tracking-tight font-extrabold drop-shadow-lg">
                            Create or Modify Stripe Data
                        </h2>

                            <div>
                                <select
                                    onChange={handlecmAction}
                                    className="text-black p-2 mb-4 rounded"
                                >
                                    <option className="text-black" value="">-- Select Create/Modify --</option>
                                    <option className="text-black" value="create">Create</option>
                                    <option className="text-black" value="modify">Modify</option>
                                </select>
                            </div>

                            {cmAction == "modify" ? 
                            <Fragment>
                                <div>
                                    <select
                                        onChange={updateType}
                                        className="text-black p-2 mb-4 rounded"
                                    >
                                        <option className="text-black" value="">-- Select a type --</option>
                                        <option className="text-black" value="customer">customer</option>
                                        <option className="text-black" value="price">price</option>
                                        <option className="text-black" value="subscription">subscription</option>
                                        <option className="text-black" value="product">product</option>
                                        <option className="text-black" value="subItem">subItem</option>
                                    </select>
                                </div>
                                {type == "customer" ? 
                                <DisplayObject 
                                    object={customers} 
                                    editCallback={editCallback} 
                                    deleteCallback={deleteCallback} 
                                    type="customer"
                                    editable={["address", "description", "email", "metadata", "name", "phone", "shipping", "tax"]}
                                    behavior="modify"
                                ></DisplayObject> 
                                : null}
                                {type == "price" ? 
                                <DisplayObject 
                                    object={prices} 
                                    editCallback={editCallback} 
                                    deleteCallback={deleteCallback} 
                                    type="price"
                                    editable={["active", "metadata", "nickname", "tax_behavior", "currency_options", "lookup_key", "transfer_lookup_key"]}
                                    behavior="modify"
                                ></DisplayObject> 
                                : null}
                                {type == "subscription" ? 
                                <DisplayObject 
                                    object={subscriptions} 
                                    editCallback={editCallback} 
                                    deleteCallback={deleteCallback} 
                                    type="subscription"
                                    editable={["cancel_at_period_end", "default_payment_method", "description", "items", "metadata", 'payment_behavior', 'proration_behavior']}
                                    behavior="modify"
                                ></DisplayObject> 
                                : null}
                                {type == "product" ? 
                                <DisplayObject 
                                    object={products} 
                                    editCallback={editCallback} 
                                    deleteCallback={deleteCallback} 
                                    type="product"
                                    editable={["active", "default_price", "description", "metadata", "name", 'tax_code', 'images', 'marketing_features', 'package_dimensions', 'shippable', 'statement_descriptor', 'unit_label', 'url']}
                                    behavior="modify"
                                ></DisplayObject> 
                                : null}
                                {type == "subItem" ? 
                                <DisplayObject 
                                    object={subItems} 
                                    editCallback={editCallback} 
                                    deleteCallback={deleteCallback} 
                                    type="subItem"
                                    editable={["active", "default_price", "description", "metadata", "name", 'tax_code', 'images', 'marketing_features', 'package_dimensions', 'shippable', 'statement_descriptor', 'unit_label', 'url']}
                                    behavior="modify"
                                ></DisplayObject> 
                                : null}
                            </Fragment>
                        : null }

                        {cmAction == "create" ? 
                            <Fragment>
                                <div>
                                    <select
                                        onChange={updateCType}
                                        className="text-black p-2 mb-4 rounded"
                                    >
                                        <option className="text-black" value="">-- Select a type --</option>
                                        <option className="text-black" value="customer">customer</option>
                                        <option className="text-black" value="price">price</option>
                                        <option className="text-black" value="subscription">subscription</option>
                                        <option className="text-black" value="product">product</option>
                                        <option className="text-black" value="subItem">subItem</option>
                                    </select>
                                </div>


                                {typec == "customer" ? 
                                <DisplayObject 
                                    object={exampleObjects.customer} 
                                    createCallback={createObjects}
                                    type="customer"
                                    editable={exampleObjects.customerEdit}
                                    behavior="create"
                                ></DisplayObject> 
                                : null}
                                {typec == "price" ? 
                                <DisplayObject 
                                    object={exampleObjects.price} 
                                    createCallback={createObjects}
                                    type="price"
                                    editable={exampleObjects.priceEdit}
                                    behavior="create"
                                ></DisplayObject> 
                                : null}
                                {typec == "subscription" ? 
                                <DisplayObject 
                                    object={exampleObjects.subscription} 
                                    createCallback={createObjects}
                                    type="subscription"
                                    editable={exampleObjects.subscriptionEdit}
                                    behavior="create"
                                ></DisplayObject> 
                                : null}
                                {typec == "product" ? 
                                <DisplayObject 
                                    object={exampleObjects.product} 
                                    createCallback={createObjects}
                                    type="product"
                                    editable={exampleObjects.productEdit}
                                    behavior="create"
                                ></DisplayObject> 
                                : null}
                                {typec == "subItem" ? 
                                <DisplayObject 
                                    object={exampleObjects.subItem} 
                                    createCallback={createObjects}
                                    type="product"
                                    editable={exampleObjects.subItemEdit}
                                    behavior="create"
                                ></DisplayObject> 
                                : null}
                            </Fragment>
                            : null }
                            
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
};

EditObject.propTypes = {

};

const connection = connect((state) => ({}), {
    GetObject: objectActions.GetObject,
    UpdateObject: objectActions.UpdateObject,
    DeleteObject: objectActions.DeleteObject,
    CreateObject: objectActions.CreateObject,
})(EditObject);
export { connection as EditObject };