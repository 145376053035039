import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import './css/alert.css';
import App from './App/App.js';
import { configureStore } from '@reduxjs/toolkit'
import { Provider } from 'react-redux'
import rootReducer from './Redux/index';

import { AuthProvider } from './Context/AuthContext';

//Redux
const store = configureStore({
  reducer: rootReducer,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <AuthProvider>
      <App />
    </AuthProvider>
  </Provider>
);

